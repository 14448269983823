import { gql, useQuery } from "@apollo/client"
import React, { useContext, useEffect, useState } from "react"
import * as moment from "moment"
import Layout from "../../components/layout"
import { useCartContext, CartProvider } from "../../views/cart/CartContext"
import "./OrderSuccess.scss"
function OrderSuccess() {
  const {state} = useCartContext()
  const dataOrderSC = state?.dataUser
  const showTotal = () => {
    let Total = 0
    dataOrderSC?.order?.lineItems?.edges.map(el => {
      if (el.node.total === null) {
        Total = 0
      } else {
        Total += Number(el.node.total)
      }
    })
    return Total
  }
  return (
    <Layout>
      <div className="wrap_all_order_success">
        <div className="wrap_order_success">
          <div className="content_mid_checkout_success_page">
            <h2>Order received</h2>
            <hr />
            <div className="content_mid_checkout_success_page_bottom">
              <p>Thank you. Your order has been received.</p>
              <ul>
                <li>
                  Order number:{" "}
                  <span>{dataOrderSC?.order ? dataOrderSC.order.orderNumber : ""}</span>
                </li>
                <li>
                  Date:{" "}
                  <span>
                    {moment(dataOrderSC?.order ? dataOrderSC.order.date : "").format("LL")}
                  </span>
                </li>
                <li>
                  Total: <span>${showTotal()}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="order_details">
            <h3>Order details </h3>
            <div className="title_table">
              <span>Product</span>
              <span>Total</span>
            </div>
            {dataOrderSC?.order?.lineItems?.edges.map((el, index) => (
              <div className="list_products" key={index}>
                <p>
                  {el.node.product.node.name} <b>x</b> <b>{el.node.quantity}</b>
                </p>
                <span>${el.node.total !== null ? el.node.total : 0}</span>
              </div>
            ))}
            <div className="sub_total">
              <h4>Subtotal:</h4>
              <span>${showTotal()}</span>
            </div>
            <div className="total">
              <h4>Total:</h4>
              <span>${showTotal()}</span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default OrderSuccess